import React from 'react'
import './images_swiper_b1.css'
import Swiper from 'swiper'
import PropTypes from 'prop-types'


export default class ImagesSwiperB1 extends React.Component {
  static propTypes={
    imageUrls:PropTypes.array.isRequired
  }
  componentDidMount() {
    var swiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  }

  render(){
    // const a2=this.props.imageUrls.map((image,index)=><div key={index} className="swiper-slide" style={{backgroundImage:`url(${require("../a2/3.png")})`}} ></div>)

    const images=this.props.imageUrls.map((image,index)=><div key={index} className="swiper-slide" style={{backgroundImage:'url(' +image + ')'}} ></div>)
    return (
      <div>
        <div className="swiper-container swiper-b1">
          <div className="swiper-wrapper">
            {images}
          </div>
        </div>
      </div>
    )
  }


}