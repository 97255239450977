import React from "react"
import LayoutB1 from "../../components/layout-b1"
import './b1_home.css'
import ImagesSwiperB1 from '../../components/images_swiper_b1'
import MyNavLink from '../../components/my-nav-link'
import { navigate } from "@reach/router"

class B1Home extends React.Component {
  toRacesList=()=>{
    navigate('/demos/themeB1/races')
  }
  toArticlesList=()=>{
    navigate('/demos/themeB1/articles')
  }
  toRaceDetail=(e)=>{
    navigate('/demos/themeB1/race_detail')
  }
  toArticleDetail=(e)=>{
    navigate('/demos/themeB1/notice_detail')
  }
  render() {
    const aboutUsDesc='夷陵放郡，宜昌新府。水至此而夷，山至此而陵，宜山宜水，始有其昌。西控巴蜀，东接荆襄，千年要冲，地僻且荒。永叔为更，施善政德法于斯，风俗一载而淳，万民敬仰!'
    const imageUrls=['//cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      '      //cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/ed8ef306-5795-4ec4-ad65-7c405ea8c18b_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/0f901f59-365a-48cf-9bdb-745aae9784cc_900x1190.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/1a854aa1-e2f5-42f6-b27c-e94a4890d086_900x637.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/21d92fcc-5ba6-450a-8c7f-43042769f7cf_900x474.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/591f320c-cd80-4882-b8c8-22e1b9eca053_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/5d76a590-9d11-4e59-aebb-9eaaf89cf752_900x642.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/75b7f65c-e685-493a-a79d-e4796028c6dc_900x651.jpeg']
    const races=[{name:"苏州马拉松国际赛事",path:'//www.nj-marathon.org/'},{name:"南京马拉松赛事",path:'//www.nj-marathon.org/'},{name:"连云港友好马拉松赛事"}]
    const raceslist=races.map((race,index)=><div key={index} className="race" onClick={()=>this.toRaceDetail(index)}>
      <a href=''><div className="racename">{index+1}、{race.name}</div></a>
    </div>)
    const poster='//img.geexek.com/files/enroll/cmpt/images/b3782d56-57b8-4eef-8480-76a79b54ca1a.png'
    const articles=[{title:'泰马虐我千百遍，我待泰马如初恋',img:'//stor.ihuipao.com/image/c15640fb80fdfd701d8e39fd090e980c.jpg?imageView2/2/w/1600/q/90',desc:'五年前，一个电话，一句相，一份深深的同窗情谊，平时连5公里都没有跑过的我就匆匆踏上泰马的“魔鬼”赛道。激情与兴奋相伴，痛苦与煎熬并存。想着自己夸下的海口，想着给儿子许下的奖牌承诺，看看漫漫长坡，即使痛苦，依然坚持着，奔跑着。因为我相信自己一定能完成，也必须完成...'},
      {title:'想约宜马情醉三峡一—宜马小记',img:'//www.yichangmarathon.com/upload/site/1105/img/indexnewsfocus/201811/05/a520d686-56ad-40e0-a9ec-43f03498cece.png?_t_=1541453732',desc:'夷陵放郡，宜昌新府。水至此而夷，山至此而陵，宜山宜水，始有其昌。西控巴蜀，东接荆襄，千年要冲，地僻且荒。永叔为更，施善政德法于斯，风俗一载而淳，万民敬仰!'}]
    const articleslist=articles.map((article,index)=><div key={index} className="article" onClick={()=>this.toArticleDetail(index)}>
      <div className="article-left-container">
        <div className="article-title">{article.title}</div>
        <div className="article-desc">{article.desc}</div>
      </div>
      <div className="article-right">
        <div className="article-img" style={{backgroundImage:'url('+article.img+')'}}>
        </div>
      </div>

    </div>)



    return(
      <LayoutB1 location={this.props.location} >
        <div className="home">
          <div className="home-top" >
            <div className="home-top-search">
              <input placeholder="请输入搜索内容"/>
              <MyNavLink to="/demos/themeB1/search" className="home-top-search-btn" >搜索</MyNavLink>
            </div>
            <div className="home-search-tips">
              <div>赛事</div>
              <div>咨询</div>
              <div>装备</div>
            </div>
          </div>

          <div className="poster-b1" ></div>
          <div>
            <ImagesSwiperB1 imageUrls={imageUrls}/>
          </div>
          <div className="fast-btn-container">
            <MyNavLink className="enroll-btn-container-phone" to='/demos/themeB1/race_detail'>
              <div className="enroll-btn-b1">
                <img src={require('../../images/b1/enroll.svg')} alt="报名icon"/>
                <div>一键报名</div>
              </div>
            </MyNavLink>
            <MyNavLink className="check-btn-container-phone" to='/demos/themeB1/search_grades'>
              <div className="check-btn">
                <img src={require('../../images/b1/query_results.svg')} alt="成绩icon"/>
                <div>查看成绩</div>
              </div>
            </MyNavLink>
          </div>
          <div className='hot-races'>
            <div className="hot-races-title">
              <div>热门赛事</div>
              <div className="more-btn" onClick={this.toRacesList}>more</div>
            </div>
            <div className="races-list">
              {raceslist}
            </div>
          </div>

          <div className="articles">
            <div className="articles-title">
              <div>文章趣事</div>
              <div className="more-btn" onClick={this.toArticlesList}>more</div>
            </div>
            <div >
              {articleslist}
            </div>
          </div>

          <div className="about-us">
            <div className="about-us-title">关于我们</div>
            <div className="about-us-content">
              <div className="desc">
                {aboutUsDesc}
              </div>
              <div className="logos-container">
                <div className="logo-b1 logo-top">
                  <img src={require('../../images/b1/logo.png')}/>
                </div>
                <div className="logo-b1 logo-left">
                  <img src={require('../../images/b1/logo.png')}/>
                </div>
                <div className="logo-b1 logo-right">
                  <img src={require('../../images/b1/logo.png')}/>
                </div>
                <div className="logo-b1 logo-bottom">
                  <img src={require('../../images/b1/logo.png')}/>
                </div>
              </div>
            </div>
          </div>
          <MyNavLink className="enroll-btn-container" to='/demos/themeB1/race_detail'>
            <div className="enroll-btn-b1">
              <img src={require('../../images/b1/enroll.svg')} alt="报名icon"/>
              <div>一键报名</div>
            </div>
          </MyNavLink>
          <MyNavLink className="check-btn-container" to='/demos/themeB1/search_grades'>
            <div className="check-btn">
              <img src={require('../../images/b1/query_results.svg')} alt="成绩icon"/>
              <div>查看成绩</div>
            </div>
          </MyNavLink>
        </div>
      </LayoutB1>
    )
  }
}

export default B1Home